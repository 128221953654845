
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import MultiselectWithButton from "@/components/UI/MultiselectWithButton.vue";
import { FilterMatchMode } from "primevue/api";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";
import CustomerService from "@/services/CustomerService";
import SalesService from "@/services/SalesService";
import Utils from "@/utility/utils";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";

import FieldServiceOrderService from "@/services/FieldServiceOrderService";
import { FieldServiceOrder } from "@/types/state/fieldServices";
import Customer from "@/types/customer";

const customerService = new CustomerService();

const fieldServiceOrderService = new FieldServiceOrderService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export default defineComponent({
  name: "FieldServiceDataWrapper",
  components: {
    DataTable,
    Button,
    InputText,
    Column,
    Calendar,
    LoadingSpinner,
    MultiselectWithButton,
    EmailFileDialog,
  },
  created() {
    if (this.customerIndex !== undefined) {
      this.filters.cust_name.value = [this.getCurrentCustomer.name];
      this.selectedFilterCusts = [this.getCurrentCustomer];
    } else {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
    }
  },
  computed: {
    ...mapGetters({
      getLoadingPDFs: "fieldServices/getLoadingPDFs",
      getFieldServices: "fieldServices/getFieldServices",
      getRangeEnd: "sales/getRangeEnd",
      getFirstRow: "sales/getFirstRow",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
      getClient: "session/getClient",
      getUser: "session/getUser",
      reportTypes: "fieldServiceControl/getReportTypes",
      getFilter: "session/getFilter",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      getInactiveCustomer: "mrkControl/filterInactiveCustomers",
      // getFieldServiceById:"fieldServices/getFieldServiceById",
    }),
  },
  props: {
    customerIndex: Number,
    currentView: String,
    isResultView: {
      type: Boolean,
      default: true,
    },
    maxExportRows: {
      type: Number,
      required: true,
    },
    showSelectionCheckbox: { type: Boolean, default: false },
    showCustomer: { type: Boolean, default: true },
    preSelectedCustomer: {
      type: Object,
      default: () => {
        return {
          name: "",
          cust_id: "",
        } as any;
      },
    },
  },
  emits: ["update-badge", "lastUpdated", "customer-view-click"],
  data() {
    return {
      printExportItems: [] as Array<any>,
      items: [] as Array<any>,
      selectedSalesID: "",
      selectedStatus: "",
      dateStart: "",
      dateEnd: "",
      selectedSale: null,
      selectedRow: null,
      id: "",
      showLoadExistingQuoteBtn: false,
      orderRecordsBy: "DEC",
      selectedSalesStatus: null,
      readOnly: false,
      sortOrder: -1,
      sortField: "conv_service_date",
      first: 0,
      rows: 25,
      page: 1,
      isLoadingData: false,
      formatedLists: [] as Array<any>,
      fsoIdToEmail: "",
      fsoTypeToEmail: "",
      customerContacts: [] as Array<any>,
      showEmailDialog: false,
      status: [
        { status: "Posted", initial: "P" },
        { status: "Complete", initial: "C" },
        { status: "Open", initial: "O" },
      ],
      statusLabel: {
        O: "Open",
        C: "Complete",
        P: "Posted",
      },
      filters: {
        cust_name: {
          value: [] as any,
          matchMode: FilterMatchMode.IN,
        },
        order_no: {
          value: "",
          matchMode: FilterMatchMode.EQUALS,
        },
        status: {
          value: [] as any[],
          matchMode: FilterMatchMode.IN,
        },
        date: {
          value: [] as any[],
          matchMode: FilterMatchMode.IN,
        },
      },
      showSalesDialog: false,
      salesOrder: null as unknown,
      loadingSalesOrderPDFs: [] as Array<string>,
      selectedFilterCusts: [] as any[],
      searchedFilterCusts: [] as any[],
      salesService: new SalesService(),
      selectedFilterId: "",
      selectedFilterStatus: [] as any[],
      selectedFilterDate: undefined as any,
      totalRecords: 0,
      rangeStart: 1,
      rangeEnd: 100,
      allExportColumns: [
        { field: "order_no", header: "Order No", input: true },
        { field: "cust_name", header: "Customer", input: true },
        { field: "service_unit", header: "Serviced By", input: true },
        { field: "order_type", header: "Type", input: true },
        { field: "conv_service_date", header: "Service Date", input: true },
        { field: "status", header: "Status", input: true },
      ],
      dynamicColumns: [
        { field: "order_no", header: "Order No", input: true },
        { field: "cust_name", header: "Customer", input: true },
        { field: "service_unit", header: "Serviced By", input: true },
        { field: "order_type", header: "Type", input: true },
        { field: "conv_service_date", header: "Service Date", input: true },
        { field: "status", header: "Status", input: true },
      ],
    };
  },
  watch: {
    getCurrentCustomer() {
      if (
        this.getCurrentCustTabIndex == this.customerIndex &&
        this.getCurrentCustomer
      ) {
        this.filters.cust_name.value = [this.getCurrentCustomer.name];
        this.selectedFilterCusts = [this.getCurrentCustomer];
        this.filterCust();
      }
    },

    "preSelectedCustomer.cust_id": function () {
      if (this.preSelectedCustomer && this.preSelectedCustomer.cust_id !== "") {
        this.setPreSelectedCustomer(this.preSelectedCustomer);
      }
    },

    items() {
      if (!this.isResultView) {
        this.formatBoardList();
      }
    },
    isResultView() {
      this.selectedFilterId = "";
      this.filters.order_no.value = "";
      this.selectedFilterStatus = [];
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate = null;
      this.filters.date.value = [];
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
      if (this.preSelectedCustomer && this.preSelectedCustomer.cust_id !== "") {
        this.setPreSelectedCustomer(this.preSelectedCustomer);
      }
      if (!this.isResultView) {
        this.formatBoardList();
      }
      this.fetchFieldServiceData(false);
    },
  },
  mounted() {
    this.first = this.getFirstRow;
    if (this.preSelectedCustomer && this.preSelectedCustomer.cust_id !== "") {
      this.setPreSelectedCustomer(this.preSelectedCustomer);
    } else {
      this.fetchFieldServiceData(false);
    }
  },
  unmounted() {
    this.clearAndResetOrders();
  },
  methods: {
    ...mapActions({
      fetchOrdersRecords: "sales/fetchOrdersRecords",
      setFirstRow: "sales/setFirstRow",
      clearAndResetOrders: "sales/clearAndResetOrders",
      getFieldServicePDF: "fieldServices/getFieldServicePDF",
      openPDF: "fieldServices/openPDF",
      addNewFieldService: "fieldServices/addNewFieldService",
      addNotification: "notification/add",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
      getMrkControl: "mrkControl/getMrkControl",
    }),
    clearAllFilters() {
      this.selectedFilterId = "";
      this.selectedFilterStatus = [];
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate = null;
      this.filters.order_no.value = "";
      this.filters.status.value = [];
      this.filters.date.value = [];
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
    },
    clearAllFiltersAndFetch() {
      this.clearAllFilters();
      this.fetchFieldServiceData(false);
    },
    setPreSelectedCustomer(preSelectedCustomer: any) {
      this.filters.cust_name.value = [preSelectedCustomer.name];
      this.selectedFilterCusts = [preSelectedCustomer];
      this.filterCust();
    },
    formatStatus(status: string) {
      switch (status) {
        case "P":
          return "Posted";
        case "C":
          return "Complete";
        case "O":
          return "Open";
        default:
          return status;
      }
    },
    showRow(event: any) {
      this.salesOrder = event;
      this.openSales(true);
      this.id = event.order_no;
    },
    async rowClick(event: any) {
      // don't fire for selection checkbox
      if (
        event.originalEvent &&
        event.originalEvent.target.className.includes("p-checkbox-icon")
      ) {
        return;
      }

      if (this.currentView === "customers-field-services") {
        this.$emit("customer-view-click", event.data);
      } else {
        if (event.data) {
          // fetch the full record here
          var data = await fieldServiceOrderService.getFieldServiceById(
            this.getClient,
            event.data.order_no,
            "",
            "",
          );
          this.addNewFieldService(data.fso_items[0]);
          this.$router.push(`/fieldservices/${event.data.order_no}`);
        } else {
          this.addNewFieldService(event);
          this.$router.push(`/fieldservices/${event.order_no}`);
        }
      }
    },
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
      this.showLoadExistingQuoteBtn = false;
    },
    pageClick(event: any) {
      this.setFirstRow(event.first);
      if (
        (event.page === event.pageCount ||
          event.page === event.pageCount - 1 ||
          event.page === event.pageCount - 2) &&
        this.items.length == this.rangeEnd
      ) {
        this.rangeEnd += 100;
        this.totalRecords = 0;

        this.fetchFieldServiceData(true);
      }
    },
    async sortData(event: any) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;

      this.fetchFieldServiceData(false);
    },
    handleCustomerSelectFilter: _debounce(async function (event) {
      const customers = await customerService.getAllCustomers({
        selection: event.value,
      });
      // @ts-expect-error becuas of exploit this
      this.searchedFilterCusts = _uniq([
        // @ts-expect-error becuas of exploit this
        ...customers.cust_items.sort((a: any, b: any) =>
          a.name.localeCompare(b.name),
        ),
        // @ts-expect-error becuas of exploit this
        ...this.selectedFilterCusts,
      ]);
    }, 1000),
    getCustomersFilter() {
      return this.selectedFilterCusts.map((cust) => {
        return cust.cust_id;
      });
    },
    async printTable() {
      //block a print with no criteria applied
      if (this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > this.maxExportRows) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        await this.fetchFieldServiceData(false);
        this.setPrintData(this.printExportItems);
        this.setPrintDefaultColumns(this.dynamicColumns);
        this.setPrintAvaialbleColumns(this.allExportColumns);

        window.open("/printable-view?print=1&showSelection=1", "_blank");
      }
    },
    async fetchFieldServiceData(addOrder: boolean) {
      // reverting this to component level range values.
      // this will need some rework if we want the Print/Export to pull all FSO items, but I think that is low priority for now.
      //let rangeStart = this.rangeStart;
      //let rangeEnd = this.rangeEnd;

      //rangeStart = 1;
      //rangeEnd = this.maxExportRows;
      let sortBy = "";
      let sortOrderRecordsBy = "";

      switch (this.sortField) {
        case "order_no":
          sortBy = "order.no";
          break;
        case "cust_name":
          sortBy = "cust.name";
          break;
        case "conv_service_date":
          sortBy = "service.date";
          break;
        case "service_unit":
          sortBy = "service.unit";
          break;
        default:
          sortBy = "service.date";
          break;
      }
      if (this.sortOrder === -1) {
        sortOrderRecordsBy = "DEC";
      }

      this.isLoadingData = true;
      this.filters.order_no.value = this.selectedFilterId;
      this.filters.status.value = [...this.selectedFilterStatus];

      this.filters.date.value = [];
      if (this.selectedFilterDate) {
        if (this.selectedFilterDate[0] != null)
          this.dateStart = this.selectedFilterDate[0];
        if (this.selectedFilterDate[1] != null)
          this.dateEnd = this.selectedFilterDate[1];

        this.dateStart = this.formatStringDate(this.dateStart);
        this.dateEnd = this.formatStringDate(this.dateEnd);

        if (this.dateStart)
          this.filters.date.value.push(new Date(this.selectedFilterDate[0]));
        if (this.dateEnd)
          this.filters.date.value.push(new Date(this.selectedFilterDate[1]));
      }
      const reps: any[] = this.getFilter("CUST", "REP");

      const filterCustomers = this.getInactiveCustomer ? "Y" : "";

      fieldServiceOrderService
        .getFieldServices(
          this.getClient,
          this.filters.order_no.value,
          this.getCustomersFilter() as any,
          "order_no cust_name service_unit order_type service_date status",
          "cust_name",
          this.selectedFilterStatus,
          this.rangeStart.toString(),
          this.rangeEnd.toString(),
          this.dateStart,
          this.dateEnd || this.dateStart,
          sortBy,
          sortOrderRecordsBy,
          reps as any,
          filterCustomers,
        )
        .then((resp: any) => {
          this.isLoadingData = false;
          if (resp.fso_items) {
            this.items = resp.fso_items;
            this.items.map((item: any) => {
              item["conv_service_date"] = new Date(item.service_date);
            });
            this.$emit("lastUpdated", Date.now());
            this.printExportItems = this.items.map((item: any) => {
              return {
                order_no: item.order_no,
                cust_name: item.cust_name,
                service_unit: item.service_unit,
                order_type: this.reportType(item.order_type),
                conv_service_date: this.formatStringDate(
                  item.conv_service_date,
                ),
                status: this.formatStatus(item.status),
              };
            });

            this.totalRecords = resp.fso_items.length;
          }
        })
        .finally(() => {
          this.isLoadingData = false;
        });
      if (!addOrder) {
        this.page = 1;
        this.first = 0;
      }
    },
    clearSalesSearchBar() {
      this.selectedFilterId = "";
      this.filters.order_no.value = "";
      this.fetchFieldServiceData(false);
    },
    clearStatusSearchBar() {
      this.selectedFilterStatus = [];
      //(((this.filters as SalesOrder)['status'] as unknown) as FilterConstraints).value = null;
      this.fetchFieldServiceData(false);
    },

    clearDateFilter() {
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate = null;
      this.filters.date.value = [];
      this.fetchFieldServiceData(false);
    },

    clearCustomerSearch() {
      this.filters.cust_name.value = [];
      this.selectedFilterCusts = [];
      this.fetchFieldServiceData(false);
    },

    filterCust() {
      this.filters.cust_name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });
      this.searchedFilterCusts = [...this.selectedFilterCusts];
      this.fetchFieldServiceData(false);
    },
    formatStringDate(dueDate: string) {
      if (!dueDate || dueDate == null) return "";
      const reformatedDueDate = Utils.formatDate(dueDate);
      if (reformatedDueDate == "Invalid Date") return "";
      return reformatedDueDate;
    },
    formatCurrency: (value: string) => {
      return parseInt(value, 10).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getPDF(id: string, type: string) {
      this.getFieldServicePDF({
        client: this.getClient,
        recordId: id,
        reportType: type,
      });
    },
    pdfIcon(id: string) {
      let downloading: boolean =
        this.getLoadingPDFs.find((i: string) => i === id) !== undefined;
      if (downloading) {
        return "pi pi-spin pi-spinner";
      } else {
        return "pi pi-download";
      }
    },
    handleFilter(event: any) {
      this.$emit("update-badge", "order_nos", event.filteredValue.length);
    },
    formatBoardList() {
      let items = [...this.items];
      if (this.getCustomersFilter().length > 0) {
        items = this.items.filter((item: any) => {
          return this.getCustomersFilter().includes(item.cust_no);
        });
      }

      let controlStatusList: Array<any> = [];
      controlStatusList = JSON.parse(JSON.stringify(this.status));
      controlStatusList.unshift({
        status: "Unassigned",
      });
      controlStatusList.forEach((element) => (element.items = []));

      items.forEach((item: any) => {
        const statusIndex = controlStatusList.findIndex(
          (element) => element.initial === item.status,
        );
        if (statusIndex >= 0) {
          controlStatusList[statusIndex].items.push(item);
        } else {
          controlStatusList[0].items.push(item);
        }
      });

      this.formatedLists = controlStatusList;
    },
    async handleEmailPDF(fso: FieldServiceOrder) {
      this.fsoIdToEmail = fso.order_no;
      this.fsoTypeToEmail = fso.order_type;

      await customerService
        .getCustomer(fso.cust_no, "", "contact_name contact_email")
        .then((response: any) => {
          const customer = response as Customer;
          this.customerContacts = customer.contact_id_items;
        })
        .catch((error) => {
          this.addNotification({
            message: `Customer contacts could not load: ${error}`,
            type: "error",
          });
        })
        .finally(() => {
          this.showEmailDialog = true;
        });
    },
    sendEmail(data: any) {
      fieldServiceOrderService
        .getFieldServicePDF(
          this.fsoIdToEmail,
          this.getClient,
          data,
          this.fsoTypeToEmail,
        )
        .then((response) => {
          if (response === "success") {
            this.addNotification({
              message: `Field Service has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Field Service was not sent`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Field Service could not be sent: ${error}`,
            type: "error",
          });
        });
    },
    reportType(code: string) {
      const report = this.reportTypes.find((report: any) => {
        return report.report_type_code === code;
      });

      if (report) {
        return report.report_type_name;
      } else {
        return "";
      }
    },
  },
});
